import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Hidden from "@material-ui/core/Hidden";
import firebase from "../firebase";
import { makeStyles } from "@material-ui/core/styles";
//import GoogleIcon from "mdi-material-ui/Google";

const authProviders = [
  {
    provider: new firebase.auth.GoogleAuthProvider(),
    color: "#4285f4",
    /*icon: <GoogleIcon />,*/
    name: "Log In",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    color: (props) => props.color,
  },
}));

function AuthButton({ onClick, key, e }) {
  const classes = useStyles(e);
  return (
    <Button key={key} className={classes.root} onClick={onClick} /*startIcon={e.icon}*/>
      {e.name}
    </Button>
  );
}

export default function AuthProviders({ signIn }) {
  return (
    <>
      <Hidden xsDown>
        <Box mb={0}>
          <ButtonGroup fullWidth orientation="vertical" variant="outlined">
            {authProviders.map((e, i) => {
              return (
                <AuthButton e={e} key={i} onClick={() => signIn(e.provider)}>
                  {e.name}
                </AuthButton>
              );
            })}
          </ButtonGroup>
        </Box>
      </Hidden>
      <Hidden smUp>
        <Box mb={3}>
          <ButtonGroup fullWidth orientation="vertical" variant="outlined">
            {authProviders.map((e, i) => {
              return (
                <AuthButton e={e} key={i} onClick={() => signIn(e.provider)}>
                  {e.name}
                </AuthButton>
              );
            })}
          </ButtonGroup>
        </Box>
      </Hidden>
    </>
  );
}
