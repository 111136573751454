import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import AuthProviders from "./AuthProvider";
import { fade, makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function NavBar({ user, setSnack, Auth, Busy, Filter }) {
  const classes = useStyles();
  const signOut = () => {
    if (user) {
      Auth.signOut()
        .then(() => {
          setSnack("Signed out");
        })
        .catch((reason) => {
          const code = reason.code;
          const message = reason.message;
          switch (code) {
            default:
              setSnack(message);
              return;
          }
        });
    } else {
      Auth.signIn();
    }
    //.finally(() => setModal(null));
  };
  //open menu with
  return (
    <Toolbar>
      <Box display="flex" flexGrow={1}>
        <Typography color="inherit" variant="h6">
          {process.env.REACT_APP_TITLE}
        </Typography>
      </Box>
      {/*search*/}
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          onChange={(e) => Filter.set(e.target.value)}
          inputProps={{ "aria-label": "search" }}
        />
      </div>

      {/*log in*/}
      {/*!user && <AuthProviders signIn={signOut} />*/}

      {/*log out*/}
      {user && (
        <Button color="inherit" onClick={Auth.signOut}>
          Log out
        </Button>
      )}
    </Toolbar>
  );
}
