import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import firebase, { auth, firestore, functions } from "./firebase";
import Loading from "./jsx/Loading";
import pako from "pako";
import GUI from "./jsx/GUI";
import { v4 } from 'uuid';
import _ from 'lodash';

import dat from "./dat.json";
global.upload = () => {
  firestore
    .collection("magacin-zip")
    .doc()
    .set({
      date: firebase.firestore.FieldValue.serverTimestamp(),
      data: pako.deflate(JSON.stringify(dat), { to: "string" }),
    })
    .then(() => console.log("uploaded"));
};
global.download = () => {
  firestore
    .collection("magacin-zip")
    .orderBy("date", "desc") // Order documents by added_at field in descending order
    //.where('added_at', "<", paymentData.added_at)
    .limit(1)
    .get()
    .then((doc) =>
      doc.forEach((e) => {
        console.log(JSON.parse(pako.inflate(e.data().data, { to: "string" })));
      })
    );
};
global.adduuid = () => {
  firestore
    .collection("magacin-zip")
    .orderBy("date", "desc") // Order documents by added_at field in descending order
    //.where('added_at', "<", paymentData.added_at)
    .limit(1)
    .get()
    .then((doc) =>
      doc.forEach((e) => {
        let x = JSON.parse(pako.inflate(e.data().data, { to: "string" })).dat;
        console.log(x);
        x.forEach((e) => {
          e.uuid = v4();
        });
        console.log(x);
        firestore
          .collection("magacin-zip")
          .doc()
          .set({
            date: firebase.firestore.FieldValue.serverTimestamp(),
            data: pako.deflate(JSON.stringify({ dat: x }), { to: "string" }),
          })
      })
    )
}

function App() {
  const [loading, setLoading] = useState(true);
  const [sifre, setSifre] = useState(false); //global console log
  const [filter, setFilter] = useState("");
  const [snack, setSnack] = useState(false); //global console log

  const authAPI = {};
  authAPI.signOut = () => {
    return auth.signOut().catch((e) => {
      setSnack(e);
      throw e;
    });
  };
  authAPI.signIn = (e, p) => {
    // e = provider
    return auth.signInWithPopup(e).catch((e) => {
      setSnack(e);
      throw e;
    });
  };
  authAPI.reload = () => {
    firestore
      .collection("magacin-zip")
      .orderBy("date", "desc")
      .limit(1)
      .get()
      .then((doc) =>
        doc.forEach((e) => {
          console.log("parse");
          let foo = JSON.parse(pako.inflate(e.data().data, { to: "string" }))
          console.log(foo);
          setSifre(foo.dat.sort((a, b) => a.code > b.code));
        })
      )
      .catch((e) => {
        setSnack("error");
        console.log(e);
      });
  };
  authAPI.upload = (x) => {
    firestore
      .collection("magacin-zip")
      .doc()
      .set({
        date: firebase.firestore.FieldValue.serverTimestamp(),
        data: pako.deflate(JSON.stringify({ dat: x }), { to: "string" }),
      })
      //.then(authAPI.reload()) takes too long
      .then(() => setSifre(_.cloneDeep(x.sort((a, b) => a.code > b.code))))
      .catch((e) => {
        setSnack("error");
        console.log(e);
      });
  };
  authAPI.editCell = (cell) => {
    if (cell.uuid) {
      let newSifre = sifre;
      let idx = sifre.findIndex((e) => e.uuid === cell.uuid);
      if (idx === -1) {
        setSnack("no such uuid");
        return;
      }
      newSifre[idx] = cell;
      authAPI.upload(newSifre);
    } else {
      cell.uuid = v4();
      let newSifre = sifre;
      console.log(cell);
      newSifre.push(cell)
      authAPI.upload(newSifre);
    }
  };

  useEffect(() => {
    auth.onAuthStateChanged((u) => {
      if (u) {
        authAPI.reload();
      }
    });
    setLoading(false);
  }, []);

  return loading ? (
    <Loading />
  ) : (
      <GUI
        Auth={authAPI}
        user={auth.currentUser}
        Filter={{ get: filter, set: setFilter }}
        sifre={sifre}
        Snack={{ get: snack, set: setSnack }}
      />
    );
}

export default App;
