import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import ReactPaginate from "react-paginate";
import Toolbar from "@material-ui/core/Toolbar";
import CodeEditor from "./CodeEditor";
import TableElem from "./TableElem";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  grow: {
    flexGrow: 1,
  },
});

export default function CodeTable({ Auth, sifre, Snack, filter }) {
  const classes = useStyles();
  //const [rows, setRows] = useState(null);
  const [page, setPage] = useState(0);
  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    setFiltered(
      filter
        ? sifre.filter((e) =>
            JSON.stringify(e).toLowerCase().includes(filter.toLowerCase())
          )
        : sifre
    );
  }, [sifre, filter]);
  useEffect(() => {
    setPage(0);
  }, [filtered.length]);

  const pPage = 50;
  let rows = [];
  for (
    let i = page * pPage;
    i < Math.min((page + 1) * pPage, filtered.length);
    i++
  ) {
    rows.push(filtered[i]);
  }
  let handlePageClick = (data) => {
    setPage(data.selected);
  };

  const [selected, setSelected] = useState(null);

  console.log(selected);
  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Sifra</TableCell>
              <TableCell>Naziv</TableCell>
              <TableCell>Jedinica</TableCell>
              <TableCell align="right">Kod</TableCell>
              <TableCell align="right">Vendor</TableCell>
              {/*<TableCell align="right">U</TableCell>
              <TableCell align="right">I</TableCell>*/}
              <TableCell align="right">ref</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows.map((row) => (
                <TableElem Auth={Auth} row={row} key={row.uuid}></TableElem>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Toolbar>
        <div className={classes.grow} />
        <ReactPaginate
          previousLabel={"previous"}
          nextLabel={"next"}
          breakLabel={"..."}
          breakClassName={
            "MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedOutlined MuiButtonGroup-groupedOutlinedHorizontal MuiButtonGroup-groupedOutlinedPrimary MuiButton-outlinedPrimary"
          }
          pageCount={Math.ceil(filtered.length / pPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"MuiButtonGroup-root"}
          pageClassName={
            "MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedOutlined MuiButtonGroup-groupedOutlinedHorizontal MuiButtonGroup-groupedOutlinedPrimary MuiButton-outlinedPrimary"
          }
          previousClassName={
            "MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedOutlined MuiButtonGroup-groupedOutlinedHorizontal MuiButtonGroup-groupedOutlinedPrimary MuiButton-outlinedPrimary "
          }
          nextClassName={
            "MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedOutlined MuiButtonGroup-groupedOutlinedHorizontal MuiButtonGroup-groupedOutlinedPrimary MuiButton-outlinedPrimary "
          }
          activeClassName={
            "MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedOutlined MuiButtonGroup-groupedOutlinedHorizontal MuiButtonGroup-groupedOutlinedPrimary MuiButton-outlinedPrimary  Mui-disabled"
          }
          pageLinkClassName={"MuiButton-text"}
          previousLinkClassName={"MuiButton-text"}
          nextLinkClassName={"MuiButton-text"}
          activeLinkClassName={"MuiButton-text"}
          forcePage={page}
        />
        <div className={classes.grow} />
      </Toolbar>
      <Toolbar>
        <CodeEditor
          Auth={Auth}
          Snack={Snack}
          selected={selected}
          sifre={sifre}
        ></CodeEditor>
      </Toolbar>
    </>
  );
}
