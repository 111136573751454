import React, { useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import AuthProviders from "./AuthProvider";
import CodeTable from "./CodeTable";
import NavBar from "./NavBar";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function GUI({ Auth, user, sifre, Snack, Filter }) {
  const classes = useStyles();
  //const [showModal, setShowModal] = useState(null); //modal container
  const [isBusy, setBusy] = useState(false);
  /*const setModal = (modal) => {
    if (modal) {
      setBusy(true);
      setShowModal(modal);
    } else {
      setBusy(false);
      setShowModal(null);
    }
  };*/

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <NavBar
          user={user}
          Filter={Filter}
          Auth={Auth}
          setSnack={Snack.set}
          Busy={{ get: isBusy, set: setBusy }}
        ></NavBar>
      </AppBar>
      <Container maxWidth="xl">
        <Toolbar />
        {user ? (
          sifre ? (
            <CodeTable Auth={Auth} sifre={sifre} Snack={Snack} filter={Filter.get}></CodeTable>
          ) : (
            <Button variant="outlined" disabled={Boolean(isBusy)} onClick={Auth.reload}>
              Greska u bazi - pokusaj ponovo
            </Button>
          )
        ) : (
          <div className={classes.content}>
            <AuthProviders signIn={Auth.signIn} />
          </div>
        )}
      </Container>
      {/*showModal*/}
      <Snackbar
        autoHideDuration={3000}
        message={Snack.get}
        open={Boolean(Snack.get)}
        onClose={() => Snack.set(false)}
      />
    </div>
  );
}
