import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

export default function TableElem({ Auth, row }) {
  const [editing, setEditing] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [code, setCode] = useState(row.code);
  const [unit, setUnit] = useState(row.unit);
  const [name, setName] = useState(row.name);
  const [note, setNote] = useState(row.note);
  const [input, setInput] = useState(row.input);
  const [output, setOutput] = useState(row.output);
  const [ref, setRef] = useState(row.ref);
  const [footnote, setFootnote] = useState(row.footnote);
  useEffect(() => {
    setUploading(false);
  }, [row]);

  const editLine = () => {
    setCode(row.code);
    setUnit(row.unit);
    setName(row.name);
    setNote(row.note);
    setInput(row.input);
    setOutput(row.output);
    setRef(row.ref);
    setFootnote(row.footnote);
    setEditing(true);
  };

  return editing ? (
    <TableRow key={row.uuid}>
      <TableCell component="th" scope="row">
        <TextField
          label="code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
      </TableCell>
      <TableCell>
        <TextField
          label="name"
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </TableCell>
      <TableCell>
        <TextField
          label="unit"
          required
          value={unit}
          onChange={(e) => setUnit(e.target.value)}
        />
      </TableCell>
      <TableCell align="right">
        <TextField
          label="model"
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
      </TableCell>
      <TableCell align="right">
        <TextField
          label="vendor"
          value={footnote}
          onChange={(e) => setFootnote(e.target.value)}
        />
      </TableCell>
      {/*<TableCell align="right">
        <TextField
          label="input"
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
      </TableCell>
      <TableCell align="right">
        <TextField
          label="output"
          value={output}
          onChange={(e) => setOutput(e.target.value)}
        />
  </TableCell>*/}
      <TableCell align="right">
        <TextField
          label="ref"
          value={ref}
          onChange={(e) => setRef(e.target.value)}
        />
      </TableCell>
      <TableCell align="right">
        <Button
          variant="outlined"
          onClick={() => {
            Auth.editCell({
              uuid: row.uuid,
              code: code,
              name: name,
              unit: unit,
              note: note,
              footnote: footnote,
              input: input,
              output: output,
              ref: ref,
            });
            setUploading(true);
            setEditing(false);
          }}
        >
          Save
        </Button>
        <Button variant="outlined" onClick={() => setEditing(false)}>
          Cancel
        </Button>
      </TableCell>
    </TableRow>
  ) : uploading ? (
    <TableRow key={row.code}>
      <TableCell component="th" scope="row">
        {row.code}
      </TableCell>
      <TableCell>{row.name}</TableCell>
      <TableCell>{row.unit}</TableCell>
      <TableCell align="right">{row.note}</TableCell>
      <TableCell align="right">{row.footnote}</TableCell>
      {/*<TableCell align="right">{row.input}</TableCell>
      <TableCell align="right">{row.output}</TableCell>*/}
      <TableCell align="right">{row.ref}</TableCell>
      <TableCell align="right">
        <Loading />
      </TableCell>
    </TableRow>
  ) : (
    <TableRow key={row.code}>
      <TableCell component="th" scope="row">
        {row.code}
      </TableCell>
      <TableCell>{row.name}</TableCell>
      <TableCell>{row.unit}</TableCell>
      <TableCell align="right">{row.note}</TableCell>
      <TableCell align="right">{row.footnote}</TableCell>
      {/*<TableCell align="right">{row.input}</TableCell>
      <TableCell align="right">{row.output}</TableCell>*/}
      <TableCell align="right">{row.ref}</TableCell>
      <TableCell align="right">
        <Button variant="outlined" onClick={() => editLine()}>
          Edit
        </Button>
      </TableCell>
    </TableRow>
  );
}
