import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import firebase, { firestore } from "../firebase";
import pako from "pako";

export default function CodeEditor({ Auth, Snack, selected, sifre }) {
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [unit, setUnit] = useState("");
  const [note, setNote] = useState("");
  const [input, setInput] = useState("");
  const [output, setOutput] = useState("");
  const [ref, setRef] = useState("");
  const [footnote, setFootnote] = useState("");

  useEffect(() => {
    if (selected) {
      setCode(selected.code);
      setName(selected.name);
      setUnit(selected.unit);
      setNote(selected.note);
      setInput(selected.input);
      setOutput(selected.output);
      setRef(selected.ref);
      setFootnote(selected.footnote);
    }
  }, [selected]);

  const save = () => {
    Auth.editCell({
      code: code,
      name: name,
      unit: unit,
      note: note,
      input: input,
      output: output,
      ref: ref,
      footnote: footnote,
    });
  };

  return (
    <>
      <TextField
        label="sifra"
        required
        value={code}
        onChange={(e) => setCode(e.target.value)}
      />
      <TextField
        label="ime"
        required
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <TextField
        label="jed"
        required
        value={unit}
        onChange={(e) => setUnit(e.target.value)}
      />
      <TextField
        label="model"
        value={note}
        onChange={(e) => setNote(e.target.value)}
      />
      <TextField
        label="vendor"
        value={footnote}
        onChange={(e) => setFootnote(e.target.value)}
      />
      {/*<TextField
        label="in"
        value={input}
        onChange={(e) => setInput(e.target.value)}
      />
      <TextField
        label="out"
        value={output}
        onChange={(e) => setOutput(e.target.value)}
      />*/}
      <TextField
        label="ref"
        value={ref}
        onChange={(e) => setRef(e.target.value)}
      />
      <Button
        variant="outlined"
        disabled={Boolean(!code || !name)}
        onClick={save}
      >
        Dodaj
      </Button>
    </>
  );
}
